<script setup>
import ExchangeForm from "@/components/main/ExchangeForm.vue";
import TrustedBlock from "@/components/main/TrustedBlock.vue";
import RecentTransactions from "@/components/main/RecentTransactions.vue";
import Faq from "@/components/main/Faq.vue";
import News from "@/components/main/News.vue";
import Tutorials from "@/components/main/Tutorials.vue";
import Background from "@/components/shared/Background.vue";
import { onBeforeMount, ref } from "vue";
import axios from "axios";

const guides = ref(null);
const news = ref(null);

onBeforeMount(() => {
  axios.get("/blog?category_id=news").then(({ data }) => {
    news.value = data;
  });
  axios.get("/blog?category_id=guides").then(({ data }) => {
    guides.value = data;
  });
});
</script>

<template>
  <section class="main-index">
    <div class="wrapper">
      <div class="exchange-form-outer">
        <h1>Lightning cryptocurrency exchange</h1>
        <ExchangeForm />
      </div>
    </div>
    <Background v-once />
  </section>
  <TrustedBlock />
  <RecentTransactions />
  <Faq />
  <News
    v-if="news && news.length"
    :allButtonText="'All news'"
    :news="news"
    :title="'News'"
  />
  <Tutorials
    v-if="guides && guides.length"
    :guides="guides"
    :all-button-text="'Read all'"
  />
</template>

<style scoped>
.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 2em;
  width: 100%;
  .exchange-form-outer {
    width: 100%;
    margin: 4.4em auto 0;
    max-width: 860px;
    padding-bottom: 16em;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 740px) {
      margin-top: 7em;
      padding-top: 1.5em;
    }
    h1 {
      font-size: 2.6em;
      font-weight: 600;
      text-align: center;
      text-indent: 0;
      max-width: 19em;
      display: block;
      margin: 0 auto 1.8em;
    }
  }
}
.main-index {
  position: relative;
}
</style>
